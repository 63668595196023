import { useUserAuth } from "../context/UserAuthContext";
import GoogleButton from "react-google-button";

import {
  Collapse,
  Nav,
  Navbar,
  NavbarBrand,
  NavbarToggler,
  NavItem,
  Row,
  Col,
  Button,
} from "reactstrap";

const Header = ({ loggedIn, logout, login, addUser }) => {
  const { user, googleSignIn, logOut } = useUserAuth();

  const handleGoogleSignIn = async (e) => {
    e.preventDefault();
    await googleSignIn();
  };

  return (
    <div>
      <Navbar color="light" expand="md" light>
        <NavbarBrand href="/">EA sync Admin panel</NavbarBrand>
        <NavbarToggler onClick={function noRefCheck() {}} />
        <Collapse navbar>
          <Nav className="me-auto" navbar>
            {![process.env.REACT_APP_ADMIN_USER_UID_1, process.env.REACT_APP_ADMIN_USER_UID_2, process.env.REACT_APP_ADMIN_USER_UID_3].includes(user?.uid) ? (
              <NavItem>
                <GoogleButton onClick={handleGoogleSignIn} />
              </NavItem>
            ) : (
              <Row>
                <Col>
                  <Button color="danger" outline onClick={logOut}>
                    Exit
                  </Button>
                </Col>
              </Row>
            )}
          </Nav>
        </Collapse>
      </Navbar>
    </div>
  );
};

export default Header;
