import axios from 'axios'

const BASE_URL = 'https://api.dfs.wildcat.im/'
const instance = axios.create({
    baseURL: BASE_URL,
    timeout: 1000,
    withCredentials: false,
    headers: {
        
    }
})

export const getUsers = async () => {
    const result = await instance.get('users')
    return result.data
}

export const changeUser = async (user) => {
    const result = await instance.put('/users', user)
    return result.data
}