import {useEffect, useState} from "react";
import {Container, Table, Button, Modal, ModalHeader, ModalBody, ModalFooter} from "reactstrap";

import {changeUser, getUsers} from "../api";

const UserList = () => {
  const [users, setUsers] = useState([]);
  const [isShowDeleteModal, setIsShowDeleteModal] = useState(false);
  const [currentUser, setCurrentUser] = useState({});

  const getAllUsers = async () => {
    const result = await getUsers();
    setUsers(result);
  };

  const toggleDeleteModal = (user) => {
    setCurrentUser(user);
    setIsShowDeleteModal(prevState => !prevState);
  }

  const markToDelete = async (user) => {
    // if (!user.marked_to_delete) {
    console.log(user);
    toggleDeleteModal();
    user.marked_to_delete = true;
    user.delete_success = new Date(user.delete_success).toISOString().split("T")[0];
    user.last_ping_date = new Date(user.last_ping_date).toISOString().split("T")[0];
    await changeUser(user);
    // }
  };

  useEffect(() => {
    getAllUsers();
  }, []);

  return (
    <Container>
      <Table>
        <thead>
        <tr>
          <th>#</th>
          <th>Name</th>
          <th>Email</th>
          <th>Last PING date</th>
          <th>Delete files?</th>
          <th>Files was deleted</th>
        </tr>
        </thead>
        <tbody>
        {users.map((u) => (
          <tr key={u.user_id}>
            <th scope="row">{u.user_id}</th>
            <td>{u.name}</td>
            <td>{u.email}</td>
            <td>{new Date(u.last_ping_date).toISOString().split("T")[0]}</td>
            <td>
              <Button
                color={u.marked_to_delete ? "danger" : "primary"}
                outline
                onClick={() => toggleDeleteModal(u)}
                // disabled={u.marked_to_delete}
              >
                {u.marked_to_delete ? "Delete files" : "Mark to delete"}
              </Button>
            </td>
            <td>{u.delete_success ? "Yes" : ""}</td>
          </tr>
        ))}
        </tbody>
      </Table>
      <Modal
        isOpen={isShowDeleteModal}
      >
        <ModalHeader>
          Are you sure you want to delete all files for {currentUser.name}?
        </ModalHeader>
        <ModalBody color="red">
          Operation can not be undone!
        </ModalBody>
        <ModalFooter>
          <Button color="danger" onClick={() => markToDelete(currentUser)}>Delete</Button>
          <Button color="secondary" onClick={toggleDeleteModal}>Cancel</Button>
        </ModalFooter>
      </Modal>
    </Container>
  );
};

export {UserList as default};
