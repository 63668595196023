import "bootstrap/dist/css/bootstrap.min.css";

import { Container } from "reactstrap";

import {
  UserAuthContextProvider,
  useUserAuth,
} from "../context/UserAuthContext";

import Header from "./Header";
import UserList from "./UserList";

function AppWithoutContext() {
  const { user } = useUserAuth();

  return (
    <Container>
      <Header />
      {[process.env.REACT_APP_ADMIN_USER_UID_1, process.env.REACT_APP_ADMIN_USER_UID_2, process.env.REACT_APP_ADMIN_USER_UID_3].includes(user?.uid)  && <UserList />}
    </Container>
  );
}

const App = () => (
  <UserAuthContextProvider>
    <AppWithoutContext />
  </UserAuthContextProvider>
);

export default App;
